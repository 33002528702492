import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';
import { useParams } from 'react-router-dom';

const EditReview = () => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const { id } = useParams();
  const firestore = getFirestore();

  useEffect(() => {
    const fetchReview = async () => {
      const docRef = doc(firestore, 'reviews', id);
      const reviewDoc = await getDoc(docRef);
      if (reviewDoc.exists()) {
        const review = reviewDoc.data();
        setTitle(review.title);
        setContent(review.content);
      }
    };

    fetchReview();
  }, [id, firestore]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const docRef = doc(firestore, 'reviews', id);
      await updateDoc(docRef, { title, content });
      console.log('Review updated successfully');
    } catch (error) {
      console.error('Failed to update review', error);
    }
  };

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col md={8}>
          <h2 className="text-center">Edit Review</h2>
          <Form onSubmit={handleUpdate}>
            <Form.Group controlId="reviewTitle">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="reviewContent">
              <Form.Label>Content</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                value={content}
                onChange={(e) => setContent(e.target.value)}
              />
            </Form.Group>

            <Button variant="primary" type="submit" block>
              Update Review
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default EditReview;
