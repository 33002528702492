import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom'; // Import Link
import './Footer.css'

const Footer = () => {
  return (
    <footer>
      <Container>
        <Row className="justify-content-center">
          <Col lg={6} md={12} className="text-center">
            <h5>TwinTech Reviews</h5>
            <p>Stay updated with the latest tech reviews and buying guides.</p>
            <p>
              <Link to="/privacy-policy">Privacy Policy</Link> | <Link to="/terms-and-conditions">Terms and Conditions</Link> | <Link to="/about">About Us</Link>
            </p>
          </Col>
        </Row>
      </Container>
      <div className="footer-bottom text-center">
        &copy; {new Date().getFullYear()} TwinTech Reviews. All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;

