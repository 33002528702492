import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs, query, where } from 'firebase/firestore';
import { Card, Container, Row, Col, Spinner, Button } from 'react-bootstrap';
import { Link, useSearchParams } from 'react-router-dom'; // Import useSearchParams
import { FaStar } from 'react-icons/fa';
import './styles/Top10Reviews.css'; // Custom CSS for dark mode

const TopReviews = () => {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const firestore = getFirestore();

  // Use useSearchParams to get query parameters
  const [searchParams] = useSearchParams();
  const category = searchParams.get('category') || 'Smartphones'; // Default category

  useEffect(() => {
    const fetchReviews = async () => {
      setLoading(true);
      try {
        const reviewsCollection = collection(firestore, 'reviews');
        const q = query(reviewsCollection, where('category', '==', category));
        const querySnapshot = await getDocs(q);
        const reviewsList = querySnapshot.docs
          .map((doc) => ({ id: doc.id, ...doc.data() }))
          .sort((a, b) => b.rating - a.rating) // Sort by rating (highest first)
          .slice(0, 10); // Limit to top 10

        setReviews(reviewsList);
      } catch (error) {
        console.error('Error fetching reviews:', error);
        setReviews([]);
      } finally {
        setLoading(false);
      }
    };

    fetchReviews();
  }, [firestore, category]); // Re-run when category changes

  const renderStars = (rating) => {
    return [...Array(5)].map((_, i) => {
      const fullStar = i + 1 <= rating; // Full star
      const halfStar = i + 0.5 <= rating && i + 1 > rating; // Half star
      return (
        <FaStar 
          key={i} 
          color={fullStar ? '#ffc107' : halfStar ? '#ffc107' : 'transparent'} 
          style={halfStar ? { clipPath: 'inset(0 50% 0 0)' } : {}}
        />
      );
    });
  };

  return (
    <Container className="top-reviews-container">
      <h2 className="text-center title">Top 10 {category} Reviews</h2>
      {loading ? (
        <div className="text-center my-5">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : reviews.length > 0 ? (
        <Row>
          {reviews.map((review, index) => (
            <Col md={6} key={review.id} className="mb-4">
              <Card className="shadow-sm h-100 review-card-dark">
                <Link to={`/reviews/${review.id}`}>
                  <Card.Img 
                    variant="top" 
                    src={review.image} 
                    alt={review.title} 
                    style={{ 
                      maxHeight: '200px', 
                      objectFit: 'contain', 
                      padding: '10px' 
                    }} 
                  />
                </Link>
                <Card.Body>
                  <Link to={`/reviews/${review.id}`} className="text-decoration-none">
                    <Card.Title className="text-white">
                      {index + 1}. {review.title}
                    </Card.Title>
                  </Link>
                  <Card.Text className="text-white transparent">{renderStars(review.rating)} {review.rating} / 5</Card.Text>
                  <Card.Text className="text-white text-truncate">
                    {review.description}
                  </Card.Text>
                  <Link to={`/reviews/${review.id}`}>
                    <Button variant="primary">Read more</Button>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      ) : (
        <div className="text-center my-5">
          <p>No reviews found for the selected category.</p>
        </div>
      )}
    </Container>
  );
};

export default TopReviews;

