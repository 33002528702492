import React, { useState, useEffect } from 'react';
import { getAuth, updateEmail, updateProfile } from 'firebase/auth';
import { Container, Form, Button, Row, Col } from 'react-bootstrap';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import './styles/Profile.css'; // Link to the updated CSS

const Profile = () => {
  const auth = getAuth();
  const storage = getStorage();
  const user = auth.currentUser;
  const [email, setEmail] = useState(user ? user.email : '');
  const [displayName, setDisplayName] = useState(user ? user.displayName : '');
  const [photoURL, setPhotoURL] = useState(user ? user.photoURL : '');
  const [message, setMessage] = useState('');
  const [imageFile, setImageFile] = useState(null);

  useEffect(() => {
    if (user) {
      setEmail(user.email);
      setDisplayName(user.displayName);
      setPhotoURL(user.photoURL);
    }
  }, [user]);

  const handleUpdate = async () => {
    try {
      if (user) {
        await updateEmail(user, email);
        await updateProfile(user, { displayName, photoURL });
        setMessage('Profile updated successfully!');
      }
    } catch (error) {
      setMessage('Error updating profile: ' + error.message);
    }
  };

  const handleImageUpload = async () => {
    if (imageFile) {
      const imageRef = ref(storage, `profile-pictures/${user.uid}`);
      await uploadBytes(imageRef, imageFile);
      const downloadURL = await getDownloadURL(imageRef);
      setPhotoURL(downloadURL);
      await updateProfile(user, { photoURL: downloadURL });
      setMessage('Profile picture updated successfully!');
    }
  };

  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col md={8} className="profile-container shadow p-5">
          <h2 className="text-center mb-4">Update Profile</h2>
          {photoURL && <img src={photoURL} alt="Profile" className="img-fluid rounded-circle mb-3" />}
          <Form>
            <Form.Group controlId="formEmail" className="mt-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formDisplayName" className="mt-3">
              <Form.Label>Display Name</Form.Label>
              <Form.Control
                type="text"
                value={displayName}
                onChange={(e) => setDisplayName(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formProfileImage" className="mt-3">
              <Form.Label>Profile Picture</Form.Label>
              <Form.Control
                type="file"
                onChange={(e) => setImageFile(e.target.files[0])}
              />
            </Form.Group>
            <Button variant="primary" onClick={handleUpdate} block className="m-3">
              Update Profile
            </Button>
            <Button variant="secondary" onClick={handleImageUpload} block className="m-3">
              Upload Picture
            </Button>
            {message && <p className="text-success mt-3">{message}</p>}
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default Profile;
