import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Form, FormControl, Button, Container, Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';
import { getFirestore, doc, getDoc, collection, query, where, onSnapshot } from 'firebase/firestore';
import { debounce } from 'lodash';
import { onMessageListener } from '../firebaseConfig';
import './Header.css';

const Header = ({ handleSearch }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [userRole, setUserRole] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [unreadNotifications, setUnreadNotifications] = useState(0); // Track unread notifications
  const firestore = getFirestore();
  const auth = getAuth();
  const user = auth.currentUser;

  

  useEffect(() => {
    // Fetch user role from Firestore
    const fetchUserRole = async () => {
      if (user && !userRole) {
        const userDoc = await getDoc(doc(firestore, 'users', user.uid));
        if (userDoc.exists()) {
          setUserRole(userDoc.data().role);
        }
      }
    };

    fetchUserRole();
  }, [firestore, user, userRole]);

  useEffect(() => {
    if (user) {
      // Firestore listener for unread notifications
      const notificationsQuery = query(
        collection(firestore, 'notifications'),
        where('userId', '==', user.uid),
        where('read', '==', false)
      );

      const unsubscribeFirestore = onSnapshot(notificationsQuery, (snapshot) => {
        setUnreadNotifications(snapshot.docs.length); // Set unread notifications count
      });

      // FCM listener for real-time notifications
      onMessageListener()
        .then(payload => {
          console.log('Received foreground notification:', payload);
          setUnreadNotifications(prev => prev + 1); // Increment unread notifications count
        })
        .catch(err => console.log('Error receiving real-time notifications: ', err));

      return () => {
        unsubscribeFirestore(); // Clean up Firestore listener
      };
    }
  }, [firestore, user]);

  // Debugging to check unread notifications count
  console.log("Unread Notifications Count:", unreadNotifications);

  const onSearch = debounce((e) => {
    e.preventDefault();
    handleSearch(searchQuery);
  }, 300);

  const handleLogout = () => {
    signOut(auth);
    setExpanded(false);
  };

  const handleLinkClick = () => {
    setExpanded(false);
  };

  return (
    <>
      {/* First Navbar - Non-Collapsible */}
      <Navbar bg="light" expand="lg" className="shadow-sm">
        <Container>
          <Navbar.Brand as={Link} to="/" className="font-weight-bold">TwinTech Review</Navbar.Brand>
          <Form className="align-items-center pill-search mx-auto" onSubmit={onSearch}>
            <FormControl
              type="text"
              placeholder="Search"
              className="search-input"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <Button variant="outline-success" type="submit" className="pill-search-btn">Search</Button>
          </Form>
          <div className="d-flex align-items-center">
            {user ? (
              <>
                <Button as={Link} to="/dashboard" variant="outline-primary" className="mx-2">Profile</Button>
                <Button onClick={handleLogout} variant="outline-danger">Logout</Button>
              </>
            ) : (
              <>
                <Button as={Link} to="/login" variant="outline-primary" className="mx-2">Login</Button>
                <Button as={Link} to="/signup" variant="primary" className="mx-2">Sign Up</Button>
              </>
            )}
            {user && (
              <Link to="/notifications" className="notification-icon mx-2">
                <i className="fa fa-bell"></i> {/* FontAwesome bell icon */}
                {unreadNotifications > 0 && (
                  <Badge pill bg="danger" className="notification-badge">
                    {unreadNotifications}
                  </Badge>
                )}
              </Link>
            )}
          </div>
        </Container>
      </Navbar>

      {/* Second Navbar - Collapsible */}
      <Navbar bg="dark" variant="dark" expand="lg" className="secondary-navbar" expanded={expanded}>
        <Container>
          <Navbar.Toggle aria-controls="navbarSecondary" onClick={() => setExpanded(expanded ? false : "expanded")} />
          <Navbar.Collapse id="navbarSecondary" className="justify-content-center">
            <Nav onClick={handleLinkClick}>
              {userRole === 'admin' && (
                <>
                  <Nav.Link as={Link} to="/write-review">Write a Review</Nav.Link>
                  <Nav.Link as={Link} to="/admin">Admin</Nav.Link>
                </>
              )}
              <Nav.Link as={Link} to="/top-10?category=Smartphones">Smartphones</Nav.Link>
              <Nav.Link as={Link} to="/top-10?category=LaptopsPCs">Laptops & PCs</Nav.Link>
              <Nav.Link as={Link} to="/top-10?category=Tablets">Tablets</Nav.Link>
              <Nav.Link as={Link} to="/top-10?category=Gaming">Gaming</Nav.Link>
              <Nav.Link as={Link} to="/top-10?category=Wearables">Wearables</Nav.Link>
              <Nav.Link as={Link} to="/top-10?category=Audio">Audio</Nav.Link>
              <Nav.Link as={Link} to="/top-10?category=Smart%20Home">Smart Home</Nav.Link>
              <Nav.Link as={Link} to="/top-10?category=Cameras">Cameras</Nav.Link>
              <Nav.Link as={Link} to="/top-10?category=Accessories">Accessories</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
