import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

const ProtectedRoute = ({ children, requiredRole }) => {
  const auth = getAuth();
  const user = auth.currentUser;
  const firestore = getFirestore();
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    if (user) {
      const fetchRole = async () => {
        const userDoc = await getDoc(doc(firestore, "users", user.uid));
        if (userDoc.exists()) {
          setUserRole(userDoc.data().role);
        }
      };
      fetchRole();
    }
  }, [user, firestore]);

  if (!user) {
    return <Navigate to="/login" />;
  }

  if (requiredRole && userRole !== requiredRole) {
    return <Navigate to="/" />;  // Redirect if user doesn't have the required role
  }

  return children;
};

export default ProtectedRoute;

