import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs, doc, updateDoc } from 'firebase/firestore';
import { Table, Button } from 'react-bootstrap';

const AdminPanel = () => {
  const [users, setUsers] = useState([]);
  const firestore = getFirestore();

  useEffect(() => {
    const fetchUsers = async () => {
      const usersCollection = await getDocs(collection(firestore, 'users'));
      const usersList = usersCollection.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setUsers(usersList);
    };
    fetchUsers();
  }, [firestore]);

  const handleRoleChange = async (userId, newRole) => {
    await updateDoc(doc(firestore, 'users', userId), { role: newRole });
    setUsers(users.map(user => (user.id === userId ? { ...user, role: newRole } : user)));
  };

  return (
    <div>
      <h2>Admin Panel - Manage User Roles</h2>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Email</th>
            <th>Role</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr key={user.id}>
              <td>{user.email}</td>
              <td>{user.role}</td>
              <td>
                <Button
                  variant="success"
                  onClick={() => handleRoleChange(user.id, 'admin')}
                  disabled={user.role === 'admin'}
                >
                  Promote to Admin
                </Button>
                <Button
                  variant="warning"
                  onClick={() => handleRoleChange(user.id, 'user')}
                  disabled={user.role === 'user'}
                  className="ml-2"
                >
                  Demote to User
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default AdminPanel;
