import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

const TermsAndConditions = () => {
  return (
    <Container className="my-5">
      <Row className="justify-content-center">
        <Col md={10}>
          <Card className="p-4 shadow">
            <Card.Body>
              <Card.Title as="h2" className="mb-4">Terms & Conditions</Card.Title>
              <p><strong>Last updated:</strong> 07/09/2024</p>
              <p>
                These Terms and Conditions ("Terms") govern your use of our website www.twintechreview.com and 
                the services provided by TwinTech Review ("we," "us," "our"). By accessing or using our website, 
                you agree to comply with these Terms. If you do not agree, please do not access the site.
              </p>

              <Card.Subtitle className="mt-4 mb-2"><strong>1. Use of the Website</strong></Card.Subtitle>
              <p>We provide tech reviews to give users honest opinions and information. You may use our website for personal, non-commercial purposes.</p>

              <Card.Subtitle className="mt-4 mb-2"><strong>2. User Responsibilities</strong></Card.Subtitle>
              <p>By using our site, you agree to:</p>
              <ul>
                <li>Avoid abusive or offensive behavior.</li>
                <li>Respect intellectual property and not reproduce content without permission.</li>
                <li>Comply with applicable laws.</li>
              </ul>
              <p>Failure to comply may result in termination of access.</p>

              <Card.Subtitle className="mt-4 mb-2"><strong>3. Account Termination</strong></Card.Subtitle>
              <p>We reserve the right to terminate accounts if users violate these Terms.</p>

              <Card.Subtitle className="mt-4 mb-2"><strong>4. Content Ownership</strong></Card.Subtitle>
              <p>All content on the website is the property of TwinTech Review and cannot be reproduced without permission.</p>

              <Card.Subtitle className="mt-4 mb-2"><strong>5. Third-Party Links</strong></Card.Subtitle>
              <p>
                Our website may contain links to third-party websites such as Amazon. We are not responsible for their content or privacy practices.
              </p>

              <Card.Subtitle className="mt-4 mb-2"><strong>6. Limitation of Liability</strong></Card.Subtitle>
              <p>We are not liable for damages or losses from using our website. We make no warranties regarding the content’s accuracy.</p>

              <Card.Subtitle className="mt-4 mb-2"><strong>7. Updates to the Terms</strong></Card.Subtitle>
              <p>
                We may update these Terms from time to time. Changes will be communicated through updates to the "Last Updated" date.
              </p>

              <p className="mt-4">For questions, contact us at support@twintechreview.com.</p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default TermsAndConditions;

