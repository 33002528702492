import React, { useState, useEffect } from 'react';
import { getFirestore, collection, query, where, onSnapshot, orderBy, doc, deleteDoc, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { Container, Row, Col, ListGroup, Form, Button, Modal } from 'react-bootstrap';

const UserProfile = () => {
  const [activities, setActivities] = useState([]);
  const [filter, setFilter] = useState('all');
  const [sortOption, setSortOption] = useState('dateDesc');
  const [showModal, setShowModal] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [loading, setLoading] = useState(true);
  const auth = getAuth();
  const user = auth.currentUser;
  const firestore = getFirestore();

  useEffect(() => {
    if (user) {
      const fetchActivities = async () => {
        try {
          let activityQuery = query(
            collection(firestore, 'userActivities'),
            where('userId', '==', user.uid)
          );

          if (filter !== 'all') {
            activityQuery = query(activityQuery, where('activityType', '==', filter));
          }
          if (sortOption === 'dateAsc') {
            activityQuery = query(activityQuery, orderBy('createdAt', 'asc'));
          } else {
            activityQuery = query(activityQuery, orderBy('createdAt', 'desc'));
          }

          const unsubscribe = onSnapshot(activityQuery, async (snapshot) => {
            const activitiesData = await Promise.all(
              snapshot.docs.map(async (docSnapshot) => {
                const activityData = docSnapshot.data();

                if (activityData.reviewId) {
                  try {
                    const reviewDoc = await getDoc(doc(firestore, 'reviews', activityData.reviewId));
                    activityData.reviewTitle = reviewDoc.exists() ? reviewDoc.data().title : 'Unknown Title';
                  } catch (reviewError) {
                    console.error("Error fetching review: ", reviewError);
                  }
                }

                return {
                  id: docSnapshot.id,
                  ...activityData,
                };
              })
            );

            setActivities(activitiesData);
            setLoading(false);
          });

          return unsubscribe;
        } catch (error) {
          console.error("Error fetching activities: ", error);
          setLoading(false);
        }
      };

      fetchActivities();
    } else {
      setLoading(false);
    }
  }, [firestore, user, filter, sortOption]);

  const openModal = (activity) => {
    setSelectedActivity(activity);
    setShowModal(true);
  };

  const handleDelete = async () => {
    try {
      await deleteDoc(doc(firestore, 'userActivities', selectedActivity.id));
      setActivities(activities.filter(activity => activity.id !== selectedActivity.id));
      setShowModal(false);
    } catch (error) {
      console.error('Error deleting activity:', error);
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedActivity(null);
  };

  return (
    <Container className='mb-3 p-4 shadow-sm rounded bg-light'>
      <h2>My Comments</h2>
      <Row className="mb-4">
        <Col md={4}>
          <Form.Control as="select" value={filter} onChange={(e) => setFilter(e.target.value)}>
            <option value="all">All Activities</option>
            <option value="review">Reviews</option>
            <option value="comment">Comments</option>
            <option value="rating">Ratings</option>
          </Form.Control>
        </Col>
        <Col md={4}>
          <Form.Control as="select" value={sortOption} onChange={(e) => setSortOption(e.target.value)}>
            <option value="dateDesc">Newest First</option>
            <option value="dateAsc">Oldest First</option>
          </Form.Control>
        </Col>
      </Row>
      {loading ? (
        <p>Loading activities...</p>
      ) : (
        <ListGroup>
          {activities.map((activity, index) => (
            <ListGroup.Item key={index} className="d-flex justify-content-between align-items-start">
              <div>
                <h4 className="font-weight-bold">{activity.reviewTitle || 'Loading title...'}</h4>
                <p className="text-muted">{activity.description}</p>
                <small>{new Date(activity.createdAt.toDate()).toLocaleString()}</small>
              </div>
              <div className="mt-2">
                <Button variant="danger" size="md" className="m-2" onClick={() => openModal(activity)}>
                  Delete
                </Button>
              </div>
            </ListGroup.Item>
          ))}
        </ListGroup>
      )}

      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {`Are you sure you want to delete this activity: ${selectedActivity && selectedActivity.description}?`}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default UserProfile;
