import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

const PrivacyPolicy = () => {
  return (
    <Container className="my-5">
      <Row className="justify-content-center">
        <Col md={10}>
          <Card className="p-4 shadow">
            <Card.Body>
              <Card.Title as="h2" className="mb-4">Privacy Policy</Card.Title>
              <p><strong>Last updated:</strong> 07/09/2024</p>
              <p>
                At <strong>www.twintechreview.com</strong> ("we," "us," "our"), we are committed to protecting your privacy. 
                This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our 
                website or use our services, including Firebase sign-up and cloud messaging. 
                Please read this privacy policy carefully. If you do not agree with the terms, please do not access the site or use our services.
              </p>

              <Card.Subtitle className="mt-4 mb-2"><strong>1. Information We Collect</strong></Card.Subtitle>
              <p>
                We may collect personal data such as your email and password when you sign up for our services through Firebase authentication. 
                Additionally, we collect usage data, including IP address, browser type, device type, and interaction details via Google Analytics.
              </p>

              <Card.Subtitle className="mt-4 mb-2"><strong>2. How We Use Your Information</strong></Card.Subtitle>
              <p>We use your information to:</p>
              <ul>
                <li>Provide and manage accounts (via Firebase authentication)</li>
                <li>Send notifications using Firebase Cloud Messaging</li>
                <li>Analyze data to enhance user experience through Google Analytics</li>
                <li>Show marketing and ads through Google Ads and affiliate links</li>
              </ul>

              <Card.Subtitle className="mt-4 mb-2"><strong>3. Sharing Your Information</strong></Card.Subtitle>
              <p>We may share your information with:</p>
              <ul>
                <li>Google services, including Firebase, Google Ads, and Analytics</li>
                <li>Third-party websites such as Amazon through affiliate links</li>
              </ul>

              <Card.Subtitle className="mt-4 mb-2"><strong>4. Data Retention</strong></Card.Subtitle>
              <p>We retain your data as long as your account remains active. Once you delete your account, your data will be erased from our systems.</p>

              <Card.Subtitle className="mt-4 mb-2"><strong>5. User Rights</strong></Card.Subtitle>
              <p>You can update your profile details while logged in. To update your email or delete your account, contact us at support@twintechreview.com.</p>

              <Card.Subtitle className="mt-4 mb-2"><strong>6. Cookies & Tracking Technologies</strong></Card.Subtitle>
              <p>We use cookies and tracking technologies to collect data on your interaction with our site for improving services and displaying relevant content.</p>

              <Card.Subtitle className="mt-4 mb-2"><strong>7. International Data Transfers</strong></Card.Subtitle>
              <p>By using our services, you consent to the transfer of your data to countries outside your residence, including the United States.</p>

              <Card.Subtitle className="mt-4 mb-2"><strong>8. Children’s Privacy</strong></Card.Subtitle>
              <p>We do not knowingly collect information from children under 13. If discovered, we will delete such information.</p>

              <Card.Subtitle className="mt-4 mb-2"><strong>9. Changes to This Policy</strong></Card.Subtitle>
              <p>
                We may update this policy from time to time. Your continued use of our services after changes indicates acceptance of the updated terms.
              </p>

              <p className="mt-4">For questions, please contact us at support@twintechreview.com.</p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PrivacyPolicy;