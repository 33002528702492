import React from 'react';
import { Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './styles/HeroSection.css'; // Link to custom CSS

const HeroSection = () => {
  return (
    <Carousel>
      <Carousel.Item>
        <Link to="/reviews">
          <img
            className="d-block w-100 hero-image"
            src="https://images.unsplash.com/photo-1487611459768-bd414656ea10?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D?text=Top+2023+Tech+Picks"
            alt="First slide"
          />
          <Carousel.Caption className="carousel-caption-custom">
            <h3>Latest Tech Reviews</h3>
            <p>Explore the latest reviews</p>
          </Carousel.Caption>
        </Link>
      </Carousel.Item>

      <Carousel.Item>
        <Link to="/top-10?category=Smartphones">
          <img
            className="d-block w-100 hero-image"
            src="https://images.unsplash.com/photo-1423666639041-f56000c27a9a?q=80&w=2074&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt="Second slide"
          />
          <Carousel.Caption className="carousel-caption-custom">
            <h3>Smartphone Buyer’s Guide</h3>
            <p>Find the best smartphones of the year with our expert reviews.</p>
          </Carousel.Caption>
        </Link>
      </Carousel.Item>

      <Carousel.Item>
        <Link to="/top-10?category=Gaming">
          <img
            className="d-block w-100 hero-image"
            src="https://images.unsplash.com/photo-1527283646354-d874f249ea2b?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt="Third slide"
          />
          <Carousel.Caption className="carousel-caption-custom">
            <h3>Best Gaming Headsets</h3>
            <p>Discover top-rated headsets for immersive gaming experiences.</p>
          </Carousel.Caption>
        </Link>
      </Carousel.Item>
    </Carousel>
  );
};

export default HeroSection;


