import React, { useState, useEffect } from 'react';
import Header from './header_footer_sidebar/Header.jsx';
import { getFirestore, collection, getDocs, query, orderBy } from 'firebase/firestore';  // Import Firestore with query and orderBy
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import HeroSection from './components/HeroSection.jsx';
import ContentSection from './components/Contentsection.jsx';
import Footer from './header_footer_sidebar/Footer.jsx';
import { Container, Row, Col } from 'react-bootstrap';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ReviewDetail from './components/ReviewDetail.jsx';
import './App.css';
import PaginationComponent from './components/PaginationComponent.jsx';
import Login from './components/Login.jsx';
import SignUp from './components/SignUp.jsx';
import Profile from './components/Profile.jsx';
import ProtectedRoute from './components/ProtectedRoute.jsx';
import AdminPanel from './components/AdminPanel.jsx';
import WriteReview from './components/WriteReview.jsx';
import ReviewsList from './components/ReviewsList.jsx';
import EditReview from './components/EditReview.jsx';
import MyReviews from './components/MyReviews.jsx';
import Notifications from './components/Notifications.jsx';
import Dashboard from './components/Dashboard.jsx';
import TopReviews from './components/Top10Reviews';  // Import the TopReviews component
import PrivacyPolicy from './components/PrivacyPolicy.jsx';
import TermsAndConditions from './components/TermsAndConditions';
import AboutUs from './components/AboutUs';

// Import Firebase Cloud Messaging and related utilities
import { getMessaging, onMessage } from "firebase/messaging";
import { requestPermission } from './firebaseConfig';  // Import requestPermission function from firebaseConfig

function App() {
  const [currentUser, setCurrentUser] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [filteredReviews, setFilteredReviews] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const reviewsPerPage = 6;

  // Listen for Firebase authentication state changes
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });
    return () => unsubscribe();
  }, []);

  // Fetch reviews from Firebase Firestore
  useEffect(() => {
    const fetchReviews = async () => {
      const firestore = getFirestore();
      const reviewsCollection = collection(firestore, 'reviews');
      const q = query(reviewsCollection, orderBy('createdAt', 'desc'));  // Order by createdAt to show latest first
      const reviewsSnapshot = await getDocs(q);
      const reviewData = reviewsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setReviews(reviewData);
      setFilteredReviews(reviewData);
    };

    fetchReviews();
  }, []);

  const handleSearch = (query) => {
    const filtered = reviews.filter(review =>
      review.title.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredReviews(filtered);
  };

  // Pagination Logic
  const indexOfLastReview = currentPage * reviewsPerPage;
  const indexOfFirstReview = indexOfLastReview - reviewsPerPage;
  const currentReviews = filteredReviews.slice(indexOfFirstReview, indexOfLastReview);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Firebase Cloud Messaging - Request Notification Permission and listen for messages
  useEffect(() => {
    // Request permission to send notifications
    requestPermission();

    // Set up a listener for foreground notifications
    const messaging = getMessaging();
    const unsubscribe = onMessage(messaging, (payload) => {
      // console.log('Message received: ', payload);
      alert(`Notification: ${payload.notification.title} - ${payload.notification.body}`);
      // Customize your notification handling here (e.g., show custom UI)
    });

    return () => unsubscribe();  // Clean up the listener when the component unmounts
  }, []);

  return (
    <Router>
      <Header handleSearch={handleSearch} user={currentUser} />
      <Container className="mt-4">
        <Routes>
          <Route path="/" element={
            <>
              <HeroSection />
              <Row>
                <Col md={12}>
                  <ContentSection reviews={currentReviews} />
                  <PaginationComponent
                    reviewsPerPage={reviewsPerPage}
                    totalReviews={filteredReviews.length}
                    paginate={paginate}
                    currentPage={currentPage}
                  />
                </Col>
              </Row>
            </>
          } />
          <Route path='/home' element={
            <>
              <HeroSection />
              <Row>
                <Col md={12}>
                  <ContentSection reviews={currentReviews} />
                  <PaginationComponent
                    reviewsPerPage={reviewsPerPage}
                    totalReviews={filteredReviews.length}
                    paginate={paginate}
                    currentPage={currentPage}
                  />
                </Col>
              </Row>
            </>
          } />
          <Route path="/top-10" element={<TopReviews />} />
          <Route path="/reviews" element={<ReviewsList reviews={reviews} />} />  {/* Pass reviews to ReviewsList */}
          <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
          <Route path="/notifications" element={<ProtectedRoute><Notifications /></ProtectedRoute>} />
          <Route path="/my-reviews" element={<ProtectedRoute><MyReviews /></ProtectedRoute>} />
          <Route path="/edit-review/:id" element={<ProtectedRoute><EditReview /></ProtectedRoute>} />
          <Route path="/write-review" element={<ProtectedRoute><WriteReview /></ProtectedRoute>} />
          <Route path="/admin" element={<ProtectedRoute ><AdminPanel /></ProtectedRoute>} />
          <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/reviews/:id" element={<ReviewDetail reviews={reviews} />} />  {/* Pass reviews to ReviewDetail */}
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/about" element={<AboutUs />} />
        </Routes>
      </Container>
      <Footer />
    </Router>
  );
}

export default App;
