import React from 'react';
import { useParams } from 'react-router-dom';
import { Card, Container, Row, Col, Image, Button } from 'react-bootstrap';
import { FaStar } from 'react-icons/fa';
import Comments from './Comments.jsx';
import './styles/ReviewDetail.css';

const ReviewDetail = ({ reviews }) => {
  const { id } = useParams();
  const review = reviews.find(r => r.id === id);

  if (!review) {
    return <h2>Review not found!</h2>;
  }

  const renderStars = (rating) => {
    return [...Array(5)].map((_, i) => {
      const fullStar = i + 1 <= rating;
      const halfStar = i + 0.5 <= rating && i + 1 > rating;
      return (
        <FaStar 
          key={i} 
          color={fullStar ? '#ffc107' : halfStar ? '#ffc107' : 'transparent'} 
          style={halfStar ? { clipPath: 'inset(0 50% 0 0)' } : {}}
        />
      );
    });
  };

  const formattedDate = new Date(review.createdAt.seconds * 1000).toLocaleDateString(); 

  return (
    <Container className="mt-4 review-container">
      <Row className="justify-content-center">
        <Col md={9}>
          <Card className="shadow">
            <Image
              src={review.image}
              alt={review.title}
              className="mb-3"
              style={{ maxWidth: '100%', height: 'auto', maxHeight: '400px', objectFit: 'contain' }}
            />
            <Card.Body>
              <Card.Title as="h1">{review.title}</Card.Title>
              <Card.Text>{renderStars(review.rating)}  {review.rating} / 5</Card.Text>
              <Card.Subtitle className="text-muted mb-2">By {review.author}</Card.Subtitle>
              <Card.Text className="text-muted">Created on: {formattedDate}</Card.Text>

              <hr />
              <Row>
                <Col md={6}>
                  <Card.Text><strong>Pros:</strong></Card.Text>
                  <ul className="list-unstyled">
                    {review.pros.map((pro, index) => (
                      <li key={index}>+ {pro}</li>
                    ))}
                  </ul>
                </Col>
                <Col md={6}>
                  <Card.Text><strong>Cons:</strong></Card.Text>
                  <ul className="list-unstyled">
                    {review.cons.map((con, index) => (
                      <li key={index}>- {con}</li>
                    ))}
                  </ul>
                </Col>
              </Row>

              <Card.Text>
                <strong>Description:</strong>
                <p className="review-text">{review.description}</p>
              </Card.Text>

              {review.affiliateLinks && review.affiliateLinks.length > 0 && (
                <div className="affiliate-links mt-3">
                  <h5>Buy from:</h5>
                  <div className="d-flex flex-wrap">
                    {review.affiliateLinks.map((link, index) => {
                      try {
                        const url = new URL(link);
                        const domain = url.hostname.replace('www.', '');
                        return (
                          <Button
                            key={index}
                            href={link}
                            target="_blank"
                            rel="noopener noreferrer"
                            variant="outline-primary"
                            className="m-2"
                          >
                            {domain} - Shop Now
                          </Button>
                        );
                      } catch (e) {
                        console.error('Invalid URL:', link);
                        return null;
                      }
                    })}
                  </div>
                </div>
              )}

              {review.verdict && (
                <>
                  <hr />
                  <h5>Verdict</h5>
                  <Card.Text className="review-text">{review.verdict}</Card.Text>
                </>
              )}

              <hr />
              <div className="comment-section mt-4 p-3">
                <h5>Comments</h5>
                <Comments reviewId={review.id} reviewAuthorId={review.authorId} />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ReviewDetail;
