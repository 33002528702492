import React, { useState } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const WriteReview = () => {
  const [title, setTitle] = useState('');
  const [rating, setRating] = useState(0);
  const [pros, setPros] = useState('');
  const [cons, setCons] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState('');
  const [category, setCategory] = useState('');
  const [affiliateLinks, setAffiliateLinks] = useState('');
  const [verdict, setVerdict] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false); 
  const firestore = getFirestore();
  const auth = getAuth();
  const user = auth.currentUser;

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!user) {
      setError('You must be logged in to submit a review.');
      return;
    }

    if (!title || !rating || !description || !image || !category || !verdict) {
      setError('All fields are required.');
      return;
    }

    try {
      setLoading(true); 

      // Add review to the reviews collection
      const reviewRef = await addDoc(collection(firestore, 'reviews'), {
        title,
        rating: parseFloat(rating.toFixed(1)),
        pros: pros.split(',').map(item => item.trim()),
        cons: cons.split(',').map(item => item.trim()),
        description,
        image,
        category,
        verdict,
        affiliateLinks: affiliateLinks.split(',').map(link => link.trim()),
        author: user.displayName,
        createdAt: new Date(),
      });

      // Log activity to the userActivities collection
      await addDoc(collection(firestore, 'userActivities'), {
        userId: user.uid,
        activityType: 'review',
        description: `Submitted review: ${title}`,
        reviewId: reviewRef.id, // Reference to the submitted review
        timestamp: new Date(),
      });

      // Clear form fields after submission
      setTitle('');
      setRating(0);
      setPros('');
      setCons('');
      setDescription('');
      setImage('');
      setCategory('');
      setAffiliateLinks('');
      setVerdict('');
      setError('');
      setLoading(false); 

      console.log('Review submitted and activity logged successfully');
    } catch (error) {
      console.error('Error submitting review:', error);
      setError('Failed to submit the review. Please try again.');
      setLoading(false);
    }
  };

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col md={8}>
          <h2 className="text-center">Write a Review</h2>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="reviewTitle">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter the title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="reviewCategory">
              <Form.Label>Category</Form.Label>
              <Form.Control
                as="select"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                <option value="">Choose a category...</option>
                <option value="Smartphones">Smartphones</option>
                <option value="LaptopsPCs">Laptops & PCs</option>
                <option value="Tablets">Tablets</option>
                <option value="Gaming">Gaming</option>
                <option value="Wearables">Wearables</option>
                <option value="Audio">Audio</option>
                <option value="Smart Home">Smart Home</option>
                <option value="Cameras">Cameras</option>
                <option value="Accessories">Accessories</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="reviewRating">
              <Form.Label>Rating</Form.Label>
              <Form.Control
                type="number"
                step="0.1"
                min="1"
                max="5"
                value={rating}
                onChange={(e) => setRating(parseFloat(e.target.value))}
                placeholder="Enter a rating between 1.0 and 5.0"
              />
            </Form.Group>

            <Form.Group controlId="reviewPros">
              <Form.Label>Pros</Form.Label>
              <Form.Control
                type="text"
                placeholder="List pros separated by commas"
                value={pros}
                onChange={(e) => setPros(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="reviewCons">
              <Form.Label>Cons</Form.Label>
              <Form.Control
                type="text"
                placeholder="List cons separated by commas"
                value={cons}
                onChange={(e) => setCons(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="reviewDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                placeholder="Write your detailed review here"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="reviewImage">
              <Form.Label>Image URL</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter the image URL"
                value={image}
                onChange={(e) => setImage(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="reviewAffiliateLinks">
              <Form.Label>Affiliate Links</Form.Label>
              <Form.Control
                type="text"
                placeholder="Add affiliate links separated by commas"
                value={affiliateLinks}
                onChange={(e) => setAffiliateLinks(e.target.value)}
              />
              <Form.Text muted>
                You can add links to products (e.g., Amazon, Best Buy).
              </Form.Text>
            </Form.Group>

            {/* New Verdict Field */}
            <Form.Group controlId="reviewVerdict">
              <Form.Label>Verdict</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter your final verdict"
                value={verdict}
                onChange={(e) => setVerdict(e.target.value)}
              />
            </Form.Group>

            <Button
              variant="primary"
              block={true}
              type="submit"
              disabled={loading}
            >
              {loading ? 'Submitting...' : 'Submit Review'}
            </Button>
          </Form>
          {error && <p className="text-danger mt-3">{error}</p>}
        </Col>
      </Row>
    </Container>
  );
};

export default WriteReview;
