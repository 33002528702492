import React from 'react';
import { Container, Row, Col, Image, Card } from 'react-bootstrap';
import './styles/AboutUs.css';

const AboutUs = () => {
  return (
    <Container className="about-us-section mt-5">
      <Row className="justify-content-center mb-4">
        <Col md={12} className="text-center">
          <h1>About TwinTech Review</h1>
        </Col>
      </Row>
      
      <Row className="mb-4">
        <Col md={6} className="text-center">
          <Card className="shadow-sm p-4">
            <Image src="https://images.unsplash.com/photo-1581093804475-577d72e38aa0?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" roundedCircle fluid className="mx-auto d-block mb-3"  style={{ width: '250px', height: '250px' }} alt="Who We Are" />
            <Card.Body>
              <Card.Title>Who We Are</Card.Title>
              <Card.Text>
                We are twin brothers from Yorkshire, England, with a shared passion for tech. 
                Phil has over 20 years of experience in retail, while Jonathan brings 20+ years 
                in IT. Together, we provide well-rounded tech reviews based on hands-on experience and technical expertise.
                We combine our knowledge to evaluate product usability, design, and functionality, giving users valuable insights for informed decisions.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        
        <Col md={6} className="text-center">
          <Card className="shadow-sm p-4">
            <Image src="https://images.unsplash.com/photo-1504610926078-a1611febcad3?q=80&w=2080&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" roundedCircle fluid  className="mx-auto d-block mb-3"  style={{ width: '250px', height: '250px' }} alt="Our Mission" />
            <Card.Body>
              <Card.Title>Our Mission</Card.Title>
              <Card.Text>
                Our mission is to provide honest, detailed reviews of the latest tech products, 
                from smartphones to home devices, to help consumers make informed decisions.
                We aim to bridge the gap between retail and IT knowledge, offering practical insights into product performance, design, and longevity. We strive to guide users through the complexities of modern technology.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      
      <Row className="mb-4">
        <Col md={6} className="text-center">
          <Card className="shadow-sm p-4">
            <Image src="https://images.unsplash.com/photo-1484417894907-623942c8ee29?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" roundedCircle fluid className="mx-auto d-block mb-3"  style={{ width: '250px', height: '250px' }} alt="Our Expertise" />
            <Card.Body>
              <Card.Title>Our Expertise</Card.Title>
              <Card.Text>
                We focus on providing more than surface-level reviews. We emphasize **real-world usability**—whether it’s the latest smartphone, gaming accessory, or home device.
                Our team evaluates not just specs but how products perform in everyday scenarios. This holistic approach allows us to provide insights on durability, ease of use, and user experience that many other reviewers miss.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col md={6} className="text-center">
          <Card className="shadow-sm p-4">
            <Image src="https://images.unsplash.com/photo-1512428559087-560fa5ceab42?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" roundedCircle fluid className="mx-auto d-block mb-3"  style={{ width: '250px', height: '250px' }} alt="Our Review Process" />
            <Card.Body>
              <Card.Title>Our Review Process</Card.Title>
              <Card.Text>
                Our review process is designed for thoroughness. After researching product specifications, 
                we conduct hands-on tests focusing on factors like performance, durability, battery life, and ease of use. 
                We document every detail to ensure accuracy and offer our readers transparent, practical advice.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="text-center mt-4">
        <Col>
          <Card className="shadow-sm p-4">
            <Card.Body>
              <h3>Message from Us</h3>
              <br></br>
              <i>
                “Technology is evolving rapidly, and we’re passionate about helping you keep up. 
                Our aim is to give you the practical, hands-on advice you need to navigate the world of tech. 
                From detailed product reviews to practical tips, we’ve got you covered.”
              </i>
              
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AboutUs;


