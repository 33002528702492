import React, { useEffect, useState } from 'react';
import { getFirestore, collection, query, where, onSnapshot, doc, updateDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { ListGroup, Button } from 'react-bootstrap';
import { requestPermission, onMessageListener } from '../firebaseConfig';

const Notifications = () => {
  const [notifications, setNotifications] = useState({title:"", body:""});
  const firestore = getFirestore();
  const auth = getAuth();
  const user = auth.currentUser;

  useEffect(() => {
    requestPermission();
  
    const fetchNotifications = () => {
      const notificationsQuery = query(
        collection(firestore, 'notifications'),
        where('userId', '==', user.uid)
      );
  
      // First unsubscribe function for the Firestore snapshot listener
      const unsubscribeFirestore = onSnapshot(notificationsQuery, (snapshot) => {
        const userNotifications = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setNotifications(userNotifications);
      });
  
      return unsubscribeFirestore;
    };
  
    // Second unsubscribe function for the onMessageListener
    const unsubscribeMessaging = onMessageListener().then((payload) => {
      setNotifications({
        title: payload?.notification?.title,
        body: payload?.notification?.body,
      });
    });
  
    const unsubscribeFirestore = fetchNotifications();
  
    // Combine both cleanup functions
    return () => {
      unsubscribeFirestore(); // Clean up Firestore listener
      unsubscribeMessaging(); // Clean up message listener
    };
  }, [firestore, user]);
  

  const handleMarkAsRead = async (id) => {
    try {
      const notificationDoc = doc(firestore, 'notifications', id);
      await updateDoc(notificationDoc, { read: true });
      setNotifications(notifications.map((n) => (n.id === id ? { ...n, read: true } : n)));
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  return (
    <ListGroup>
      {notifications.map((notification) => (
        <ListGroup.Item key={notification.id} variant={notification.read ? 'light' : 'warning'}>
          {notification.message}
          {!notification.read && (
            <Button variant="primary" onClick={() => handleMarkAsRead(notification.id)} className="ml-2">
              Mark as Read
            </Button>
          )}
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
};

export default Notifications;

