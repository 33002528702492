import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './styles/ContentSection.css';
import { format } from 'date-fns';  // Import date formatting library

const ContentSection = ({ reviews }) => {
  // Sort reviews by createdAt timestamp in descending order (latest first)
  const sortedReviews = [...reviews].sort((a, b) => b.createdAt?.seconds - a.createdAt?.seconds);

  const formatDate = (timestamp) => {
    if (timestamp && timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000); // Convert Firestore timestamp to JS Date
      return format(date, 'MMM dd, yyyy');  // Format date
    }
    return 'Unknown date';
  };

  return (
    <Row className="content-section">
      {sortedReviews.map((review) => (
        <Col md={4} key={review.id} className="mb-4">
          <Card bg="dark" variant="dark" className="review-card shadow-sm h-100">
            <Link to={`/reviews/${review.id}`} className="custom-link">
              <Card.Img 
                variant="top" 
                src={review.image || 'https://via.placeholder.com/300x200'} 
                alt={review.title} 
                className="card-image" 
              />
              <Card.Body>
                <Card.Title>{review.title}</Card.Title>
                <Card.Text className="text-truncate">{review.description}</Card.Text>
                <Card.Text className="small createdon">Created on: {formatDate(review.createdAt)}</Card.Text>
              </Card.Body>
            </Link>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default ContentSection;

