// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, setPersistence, browserSessionPersistence } from 'firebase/auth';
import { getMessaging, getToken, onMessage } from "firebase/messaging";  // Import FCM

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB3V6VOxEcFpQ-JcZYuZMg1b9knI8C5L4Y",
  authDomain: "twintech-d4104.firebaseapp.com",
  projectId: "twintech-d4104",
  storageBucket: "twintech-d4104.appspot.com",
  messagingSenderId: "485872797728",
  appId: "1:485872797728:web:5ffa9629dfa77049609445",
  measurementId: "G-4VHQES1X1G"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);

// Enable session persistence
setPersistence(auth, browserSessionPersistence)
  .then(() => {
    console.log('Session persistence enabled');
  })
  .catch((error) => {
    console.error('Error setting persistence:', error);
  });

// Initialize Firebase Cloud Messaging (FCM)
const messaging = getMessaging(app);

export const requestPermission = () => {
  console.log("Requesting user permission....");

  // Function to retrieve the FCM token
  const retrieveToken = () => {
    return getToken(messaging, {
      vapidKey: 'BMcV2Mw2-w51gYgTUhqJneZMm44mnvkBPE6MJU7lwA4ew7qL2gV-Inpu73BluPSgVzBdxQy1pql2bhwrbW8xv2c' // Replace with your VAPID key
    })
      .then((currentToken) => {
        if (currentToken) {
         
        } else {
          console.log('No registration token available.');
        }
      })
      .catch((err) => {
        console.log('Error retrieving token:', err);
      });
  };

  // Check the status of the notification permission
  if (Notification.permission === "granted") {
    console.log("Notification user permission already granted");
    retrieveToken();  // Get the token if permission is already granted

  } else if (Notification.permission === "denied") {
    console.log("Notification user permission denied");

  } else if (Notification.permission === "default") {
    console.log("Notification permission is default");

    // Ask for permission if it's default (neither granted nor denied)
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        console.log("Notification user permission granted");
        retrieveToken();  // Get the token if the user grants permission
      } else {
        console.log("Notification permission not granted");
      }
    });
  }
};


// Request for push notification token
export const onMessageListener = () => 
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

requestPermission();

export { auth, analytics };
