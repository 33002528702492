import React, { useEffect, useState } from 'react';
import { getFirestore, collection, getDocs, doc, deleteDoc } from 'firebase/firestore';
import { Card, Container, Row, Col, Button } from 'react-bootstrap';
import { getAuth } from 'firebase/auth';
import { Link } from 'react-router-dom';

const MyReviews = () => {
  const [reviews, setReviews] = useState([]);
  const firestore = getFirestore();
  const auth = getAuth();
  const user = auth.currentUser;

  useEffect(() => {
    const fetchReviews = async () => {
      const querySnapshot = await getDocs(collection(firestore, 'reviews'));
      const userReviews = querySnapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .filter((review) => review.author === user.email);
      setReviews(userReviews);
    };

    fetchReviews();
  }, [firestore, user]);

  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(firestore, 'reviews', id));
      setReviews(reviews.filter((review) => review.id !== id));
    } catch (error) {
      console.error('Error deleting review:', error);
    }
  };

  return (
    <Container>
      <h2>My Reviews</h2>
      <Row>
        {reviews.map((review, index) => (
          <Col md={4} key={index} className="mb-4">
            <Card>
              <Card.Body>
                <Card.Title>{review.title}</Card.Title>
                <Card.Text>{review.content}</Card.Text>
                <Card.Subtitle className="text-muted">By {review.author}</Card.Subtitle>
                <Button variant="warning" className="mr-2" as={Link} to={`/edit-review/${review.id}`}>
                  Edit
                </Button>
                <Button variant="danger" onClick={() => handleDelete(review.id)}>
                  Delete
                </Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default MyReviews;
