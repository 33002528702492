import React, { useState, useEffect } from 'react';
import { getFirestore, collection, addDoc, query, where, getDocs } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { Form, Button, ListGroup } from 'react-bootstrap';

const Comments = ({ reviewId, reviewAuthorId }) => {  // Added reviewAuthorId as prop
  const [comments, setComments] = useState([]);
  const [comment, setComment] = useState('');
  const firestore = getFirestore();
  const auth = getAuth();
  const user = auth.currentUser;

  useEffect(() => {
    const fetchComments = async () => {
      const q = query(collection(firestore, 'comments'), where('reviewId', '==', reviewId));
      const querySnapshot = await getDocs(q);
      const commentsList = querySnapshot.docs.map(doc => doc.data());
      setComments(commentsList);
    };

    fetchComments();
  }, [firestore, reviewId]);

  const handleAddComment = async (e) => {
    e.preventDefault();
    if (!comment) return;
  
    const newComment = {
      reviewId,
      text: comment,
      author: user.displayName,
      createdAt: new Date(),
    };
    setComments([...comments, newComment]);  // Optimistically update UI
  
    try {
      // Add comment to Firestore
      await addDoc(collection(firestore, 'comments'), newComment);
  
      // // Create a notification for the review's author (optional)
      // await addDoc(collection(firestore, 'notifications'), {
      //   userId: reviewAuthorId, // Use the passed reviewAuthorId here
      //   message: `${user.email} commented on your review: ${comment}`,
      //   read: false,
      //   createdAt: new Date(),
      // });
  
      // Log the comment activity in userActivities with the reviewId
      await addDoc(collection(firestore, 'userActivities'), {
        userId: `${user.uid}`,
        activityType: "comment",
        description:`${comment}`,
        reviewId: reviewId,  // Ensure the reviewId is added here
        createdAt: new Date(),
      });
  
      setComment('');  // Clear the comment input after submission
    } catch (error) {
      console.error('Failed to add comment', error);
    }
  };

  return (
    <>
      <ListGroup className="mb-3">
        {comments.map((cmt, index) => (
          <ListGroup.Item key={index}>
            <strong>{cmt.author}:</strong> {cmt.text}
          </ListGroup.Item>
        ))}
      </ListGroup>
      <Form onSubmit={handleAddComment}>
        <Form.Group controlId="comment">
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Add a comment..."
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    </>
  );
};

export default Comments;
