import React, { useEffect, useState } from 'react';
import { getFirestore, collection, getDocs} from 'firebase/firestore';
import { Card, Container, Row, Col, Form, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaStar } from 'react-icons/fa';
import './styles/ReviewsList.css'


const ReviewsList = () => {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [ratingFilter, setRatingFilter] = useState(0);
  const [sortOption, setSortOption] = useState('dateDesc');
  const firestore = getFirestore();

  useEffect(() => {
    const fetchReviews = async () => {
      setLoading(true);
      const querySnapshot = await getDocs(collection(firestore, 'reviews'));
      const reviewsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setReviews(reviewsList);
      setLoading(false);
    };

    fetchReviews();
  }, [firestore]);

  const renderStars = (rating) => {
    return [...Array(5)].map((_, i) => {
      const fullStar = i + 1 <= rating; // Full star
      const halfStar = i + 0.5 < rating && i + 1 > rating; // Half star
      return (
        <FaStar 
          key={i} 
          color={fullStar ? '#ffc107' : halfStar ? '#ffc107' : 'transparent'} 
          style={halfStar ? { clipPath: 'inset(0 50% 0 0)' } : {}}
        />
      );
    });
  };

  const sortedReviews = [...reviews]
    .filter(review => review.title.toLowerCase().includes(searchQuery.toLowerCase()))
    .filter(review => review.rating >= ratingFilter)
    .sort((a, b) => {
      if (sortOption === 'dateAsc') {
        return new Date(a.createdAt) - new Date(b.createdAt);
      } else if (sortOption === 'dateDesc') {
        return new Date(b.createdAt) - new Date(a.createdAt);
      } else if (sortOption === 'ratingAsc') {
        return a.rating - b.rating;
      } else if (sortOption === 'ratingDesc') {
        return b.rating - a.rating;
      }
      return 0;
    });

  return (
    <Container>
      <Row className="mb-4">
        <Col md={4}>
          <Form.Control
            type="text"
            placeholder="Search by title..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </Col>
        <Col md={2}>
          <Form.Control
            as="select"
            value={ratingFilter}
            onChange={(e) => setRatingFilter(Number(e.target.value))}
          >
            <option value={0}>All Ratings</option>
            <option value={1}>1 & above</option>
            <option value={2}>2 & above</option>
            <option value={3}>3 & above</option>
            <option value={4}>4 & above</option>
            <option value={5}>5</option>
          </Form.Control>
        </Col>
        <Col md={4}>
          <Form.Control
            as="select"
            value={sortOption}
            onChange={(e) => setSortOption(e.target.value)}
          >
            <option value="dateDesc">Newest First</option>
            <option value="dateAsc">Oldest First</option>
            <option value="ratingDesc">Highest Rating</option>
            <option value="ratingAsc">Lowest Rating</option>
          </Form.Control>
        </Col>
      </Row>
      {loading ? (
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      ) : sortedReviews.length > 0 ? (
        <Row>
          {sortedReviews.map((review, index) => (
            <Col md={4} key={index} className="mb-4">
              <Card bg="dark" variant="dark" className="review-card">
  <Link to={`/reviews/${review.id}`}>
    <Card.Img variant="top"  src={review.image} alt={review.title} />
  </Link>
  <Card.Body>
    <Link to={`/reviews/${review.id}`}>
      <Card.Title>{review.title}</Card.Title>
    </Link>
    <Card.Text>{renderStars(review.rating)}</Card.Text>
  </Card.Body>
</Card>
            </Col>
          ))}
        </Row>
      ) : (
        <p>No reviews found.</p>
      )}
    </Container>
  );
};

export default ReviewsList;
